// DesktopHeader.js
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Form,
  FormControl,
  Nav,
  Navbar,
} from "react-bootstrap";
import logo from "../Header/Group 824.png";
import "./DesktopHeader.css"; // Подключение файла стилей
import SEO from "../SEO"; // Импортируем SEO компонент
export default function DesktopHeader() {
  const { i18n } = useTranslation(); // Достаем i18n
  const { t } = useTranslation(); // Достаем i18n
  const [logoSize, setLogoSize] = React.useState(80);
  const [showHeaderTop, setShowHeaderTop] = React.useState(true);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setLogoSize(50);
      setShowHeaderTop(false);
    } else {
      setLogoSize(80);
      setShowHeaderTop(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* SEO Optimization */}
      <SEO
        title="Общественный транспорт Астана - Автобусный парк №1 | Комфорт и надежность"
        description="Автобусный парк №1 в Астане предлагает удобные и экологически чистые автобусы, широкий выбор маршрутов и аренду транспорта для частных и корпоративных нужд."
        keywords="автобусный парк Астана, общественный транспорт Астана, аренда автобусов Астана, автобусные маршруты Астана, экологичные автобусы, городской транспорт, прокат автобусов, комфортные автобусы, транспортные услуги Астана"
      />

      <Navbar
        fixed="top"
        collapseOnSelect
        expand="md"
        style={{ backgroundColor: "#09F" }}
        variant="dark"
        className={`desktop-header ${!showHeaderTop ? "scrolled" : ""}`}
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              height={logoSize} // Динамическая высота логотипа
              width={logoSize * 2.5} // Динамическая ширина логотипа (соотношение 2.5)
              className={`d-inline-block align-top ${
                !showHeaderTop ? "scrolled-logo" : ""
              }`}
              alt="Logo"
            />
          </Navbar.Brand>

          <div className={`header-top w-100 ${!showHeaderTop ? "hidden" : ""}`}>
            {showHeaderTop && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="social-icons">
                  <a
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../icon/3932196.png")}
                      alt="Facebook"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/avtopark_01/?igsh=MW55cTN3Zm9hazB1NA%3D%3D"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../icon/5618785.png")}
                      alt="Instagram"
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@avtopark_01_astana"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={require("../../icon/3046114.png")} alt="TikTok" />
                  </a>
                </div>
                <div className="header-contact">
                  <a href="tel:+77172541626" className="phone-link">
                    <span className="phone-number">
                      <img
                        src={require("../../icon/1509974.png")}
                        alt="phone"
                      />
                      +7 (7172) 54 16 26
                    </span>
                  </a>
                </div>
                <div className="language-switcher">
                  <Button
                    variant="link"
                    className="language-btn"
                    onClick={() => changeLanguage("kz")}
                  >
                    ҚАЗ
                  </Button>
                  |
                  <Button
                    variant="link"
                    className="language-btn"
                    onClick={() => changeLanguage("ru")}
                  >
                    РУС
                  </Button>
                </div>
              </div>
            )}

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto w-100">
                <Nav.Link href="/company" className="custom-nav-link">
                  {t("company")}
                </Nav.Link>
                <Nav.Link href="/for-passengers" className="custom-nav-link">
                  {t("forPassengers")}
                </Nav.Link>
                <Nav.Link href="/business" className="custom-nav-link">
                  {t("business")}
                </Nav.Link>
                <Nav.Link href="/press-center" className="custom-nav-link">
                  {t("pressCenter")}
                </Nav.Link>
                <Nav.Link href="/contacts" className="custom-nav-link">
                  {t("contacts")}
                </Nav.Link>
              </Nav>
              <Form className="ml-auto d-flex">
                <FormControl
                  type="text"
                  placeholder={t("search")} // Перевод слова "Поиск"
                  className="mr-2 custom-font"
                />
                <Button
                  variant="outline-info"
                  className="custom-font-button"
                  style={{ marginLeft: "10px" }}
                >
                  {t("search")} {/* Перевод кнопки "Поиск" */}
                </Button>
              </Form>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>

      {/* Контент сайта */}
      <div className="desktop-content">{/* Весь ваш контент */}</div>
    </>
  );
}
