import React, { useState } from 'react';
import styles from './ComplianceOfficer.module.css';
import Image1 from './../../assets/5124556.jpg';
import Image2 from './../../assets/4443391.jpg';

// Импорт сертификатов (убедитесь, что файлы лежат в указанной директории)
import CertificateRu from './../../assets/certificate_ru.jpg';
import CertificateKz from './../../assets/certificate_kz.jpg';
import CertificateEn from './../../assets/certificate_en.jpg';

const ComplianceOfficer = () => {
    // Массив объектов документов. Для каждого документа задаём имя и путь (href).
    const documents = [
        {
            name: "АО Автобусный парк №1 Перечень должностей подверженных коррупционым рискам",
            href: "/documents/АО%20Автобусный%20парк%20№1%20Перечень%20должностей%20подверженных%20коррупционым%20рискам.PDF",
        },
        {
            name: "Положение антикоррупции",
            href: "/documents/Положение%20антикоррупции.docx",
        },
        {
            name: "Правила урегулирования конфликта",
            href: "/documents/Правила%20урегул.%20конфл.docx",
        },
        {
            name: "Инструкция по противодейстию коррупции",
            href: "/documents/Инструкция%20по%20противодейстию%20коррупции.PDF",
        },
        {
            name: "Политика противодействии коррупции",
            href: "/documents/Политика%20противодействии%20коррупции.PDF",
        },
        {
            name: "АО Автобусный парк №1 ВАКР 2024г.",
            href: "/documents/АО%20Автобусный%20парк%20№1%20ВАКР%202024г..PDF",
        },
        {
            name: "Анонсирование о начале публичного осуждения.",
            href: "/documents/Анонсирование%20о%20начале%20публичного%20осуждения.docx",
        },
        {
            name: "АО Автобусный парк №1 План по устранению выявленных по результатам ВАКР 2024",
            href: "/documents/АО%20Автобусный%20парк%20№1%20План%20по%20устранению%20выявленных%20по%20результатам%20ВАКР%202024.PDF",
        },
        {
            name: "АО Автобусный парк №1 приказ по ВАКР №252-п от 24.04.2024г.",
            href: "/documents/АО%20Автобусный%20парк%20№1%20приказ%20по%20ВАКР%20№252-п%20от%2024.04.2024г..PDF",
        },
        {
            name: "АО Автобусный парк №1 Приложение №7",
            href: "/documents/АО%20Автобусный%20парк%20№1%20Приложение%20№7.PDF",
        },
        {
            name: "АО Автобусный парк №1 Протокол №6 семинар совещания от 25.11.2024г.",
            href: "/documents/АО%20Автобусный%20парк%20№1%20Протокол%20№6%20семинар%20совещания%20от%2025.11.2024г..PDF",
        },
        {
            name: "Протокол обсуждения АО Автобусный парк №1",
            href: "/documents/Протокол%20обсуждения%20АО%20Автобусный%20парк%20№1.PDF",
        },
    ];

    // Состояние для контроля показа полного списка документов
    const [showAllDocs, setShowAllDocs] = useState(false);

    // Функция для переключения состояния (показать/скрыть все документы)
    const toggleDocuments = () => {
        setShowAllDocs(prevState => !prevState);
    };

    // Если список свернут, отображаем только первые 3 документа,
    // иначе показываем полный список.
    const displayedDocuments = showAllDocs ? documents : documents.slice(0, 3);

    return (
        <div className={styles.complianceContainer}>
            {/* Раздел: Горячая линия */}
            <div className={styles.hotlineSection}>
                <h2 className={styles.heading}>Горячая линия АО «Автобусный парк №1»</h2>
                <div className={styles.hotlineContent}>
                    <div className={styles.textContent}>
                        <p>
                            АО «Автобусный парк №1» (далее – Общество) уделяет особое внимание развитию и поддержанию 
                            корпоративной культуры, демонстрирующей приверженность принципам добросовестности, прозрачности и честности.
                        </p>
                        <p>
                            В целях дальнейшего сохранения положительного имиджа АО «Автобусный парк №1» сообщает о запуске Горячей линии 
                            для своевременного выявления и предотвращения нарушений законодательства и/или Кодекса поведения, в том числе 
                            случаев мошенничества, коррупции, дискриминации, неэтичного поведения и иных нарушений, относящихся к деятельности Общества.
                        </p>
                    </div>
                </div>
                <div className={styles.principles}>
                    <h3>ГОРЯЧАЯ ЛИНИЯ ОБСЛУЖИВАЕТСЯ НЕЗАВИСИМЫМ ОПЕРАТОРОМ, НА СЛЕДУЮЩИХ ПРИНЦИПАХ:</h3>
                    <div className={styles.textWithImage}>
                        <ul>
                            <li>
                                Вы можете сообщить о любых нарушениях Кодекса поведения, в том числе о фактах коррупции, дискриминации, неэтичного поведения и иных нарушениях.
                            </li>
                        </ul>
                        <img src={Image1} alt="Принципы горячей линии" className={styles.imageSmall} />
                    </div>
                </div>
                <div className={styles.guarantees}>
                    <h3>МЫ ГАРАНТИРУЕМ:</h3>
                    <div className={styles.textWithImage}>
                        <ul>
                            <li>Конфиденциальности и анонимности (по желанию обратившегося лица).</li>
                            <li>Регистрации и обработки 100% обращений с последующим предоставлением ответственным лицам Общества.</li>
                        </ul>
                        <img src={Image2} alt="Гарантии" className={styles.imageSmall} />
                    </div>
                </div>
                <div className={styles.contactBlock}>
                    <h3>
                        ОБРАЩЕНИЯ МОГУТ ПРЕДОСТАВЛЯТЬСЯ ВСЕМИ ЛИЦАМИ НА РУССКОМ, КАЗАХСКОМ И АНГЛИЙСКОМ ЯЗЫКАХ 
                        ПОСРЕДСТВОМ СЛЕДУЮЩИХ КАНАЛОВ СВЯЗИ:
                    </h3>
                    <ul>
                        <li>
                            По телефонному номеру: <strong>87171 644 082 (вн. 141)</strong>
                        </li>
                        <li>
                            На электронную почту:{' '}
                            <a href="mailto:d.temirtasovich@avtopark1.kz" className={styles.emailLink}>
                                d.temirtasovich@avtopark1.kz
                            </a>
                        </li>
                        <li>
                            Через моб. номер: <strong>8 777 772 22 22</strong>
                        </li>
                    </ul>
                </div>
                <p>
                    Все права защищены. Подробнее читайте на официальном сайте:{' '}
                    <a
                        href="https://www.avtopark1.kz/company"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://www.avtopark1.kz/company
                    </a>
                </p>
            </div>

            {/* Раздел: Комплаенс-офицер */}
            <div className={styles.complianceSection}>
                <h2 className={styles.heading}>Комплаенс-офицер</h2>
                <p><strong>Калимуллин Дулат Темиртасович</strong></p>
                <p>тел. 8 7172 644 082 (вн. 141)</p>
                <p>моб. 8 777 772 22 22</p>
                <p>
                    эл.адрес: <a href="mailto:d.temirtasovich@avtopark1.kz">d.temirtasovich@avtopark1.kz</a>
                </p>
                <h3 className={styles.subheading}>Цель деятельности Комплаенс-офицера</h3>
                <p>
                    Обеспечение соблюдения законодательства о противодействии коррупции, мониторинг за реализацией мероприятий 
                    по противодействию коррупции, формирование внутренней правовой и корпоративной культуры Общества, отвергающей 
                    коррупцию и обеспечивающей принципы честности и неподкупности при исполнении своих должностных обязанностей.
                </p>
                <h3 className={styles.subheading}>Задачи</h3>
                <ul>
                    <li className={styles.listItem}>
                        Проведение единой политики в области противодействия коррупции;
                    </li>
                    <li className={styles.listItem}>
                        Формирование у должностных лиц и работников понимания нулевой терпимости к коррупции;
                    </li>
                    <li className={styles.listItem}>
                        Минимизация риска вовлечения работников и третьих лиц в коррупционную деятельность;
                    </li>
                    <li className={styles.listItem}>
                        Разработка и внедрение антикоррупционных стандартов;
                    </li>
                    <li className={styles.listItem}>
                        Воспитание строгого соблюдения антикоррупционного законодательства;
                    </li>
                    <li className={styles.listItem}>
                        Недопущение коррупциогенных факторов в нормативных документах;
                    </li>
                    <li className={styles.listItem}>
                        Обеспечение открытости и объективности при выполнении работ.
                    </li>
                </ul>
                <h3 className={styles.subheading}>Меры по предупреждению и противодействию коррупции</h3>
                <p>Направления деятельности, где возможно возникновение коррупционного риска:</p>
                <ol>
                    <li className={styles.listItem}>Подарки и представительские расходы;</li>
                    <li className={styles.listItem}>Привлечение третьих лиц в вопросах противодействия коррупции;</li>
                    <li className={styles.listItem}>Платежи лицам, занимающим государственные должности;</li>
                    <li className={styles.listItem}>Оказание спонсорской и благотворительной помощи;</li>
                    <li className={styles.listItem}>Совершение сделок и проведение госзакупок;</li>
                    <li className={styles.listItem}>Управление персоналом;</li>
                    <li className={styles.listItem}>Финансовая и юридическая экспертиза документов.</li>
                </ol>
                <h3 className={styles.subheading}>Документы:</h3>
                <ul className={styles.list}>
                    {displayedDocuments.map((doc, index) => (
                        <li key={index}>
                            <a
                                href={doc.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {doc.name}
                            </a>
                        </li>
                    ))}
                </ul>
                {/* Кнопка для переключения отображения списка документов */}
                {documents.length > 3 && (
                    <button onClick={toggleDocuments} className={styles.toggleButton}>
                        {showAllDocs ? 'Свернуть список документов' : 'Показать все документы'}
                    </button>
                )}
            </div>

            {/* Раздел: Сертификаты деп */}
            <div className={styles.certificatesSection}>
                <h3 className={styles.subheading}>Сертификаты</h3>
                <div className={styles.certificatesList}>
                    <div className={styles.certificate}>
                        <img
                            src={CertificateRu}
                            alt="Сертификат (русский)"
                            className={styles.certificateImage}
                        />
                        <p>Сертификат (русский)</p>
                    </div>
                    <div className={styles.certificate}>
                        <img
                            src={CertificateKz}
                            alt="Сертификат (казахский)"
                            className={styles.certificateImage}
                        />
                        <p>Сертификат (казахский)</p>
                    </div>
                    <div className={styles.certificate}>
                        <img
                            src={CertificateEn}
                            alt="Certificate (английский)"
                            className={styles.certificateImage}
                        />
                        <p>Certificate (английский)</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComplianceOfficer;
