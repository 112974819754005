import React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>{t("footer_company")}</h4>
          <ul>
            <li>
              <a href="/company?tab=activity">{t("footer_activity")}</a>
            </li>
            <li>
              <a href="/company?tab=leadership">{t("footer_leadership")}</a>
            </li>
            <li>
              <a href="/company?tab=partners">{t("footer_partners")}</a>
            </li>
            <li>
              <a href="/company?tab=vacancies">{t("footer_vacancies")}</a>
            </li>
            <li>
              <a href="/company?tab=best-employees">
                {t("footer_best_employees")}
              </a>
            </li>
            <li>
              <a href="/company?tab=compliance-officer">
                {t("footer_compliance_officer")}
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>{t("footer_passengers")}</h4>
          <ul>
            <li>
              <a href="#">{t("footer_routes")}</a>
            </li>
            <li>
              <a href="#">{t("footer_transport_cards")}</a>
            </li>
            <li>
              <a href="#">{t("footer_recharge_points")}</a>
            </li>
            <li>
              <a href="#">{t("footer_rules")}</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>{t("footer_info")}</h4>
          <ul>
            <li>
              <a href="#">{t("footer_contacts")}</a>
            </li>
            <li>
              <a href="#">{t("footer_news")}</a>
            </li>
            <li>
              <a href="#">{t("footer_recharge")}</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
