import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './PartnersSection.css';  // Подключаем файл со стилями

const PartnersSection = () => {
  const { t } = useTranslation(); // Используем i18next для перевода

  return (
    <>
      <Helmet>
        <title>{t('partners.title')} - Автобусный Парк №1</title>
        <meta name="description" content={t('partners.description')} />
        <link rel="canonical" href="https://вашсайт.kz/business/partners" />
      </Helmet>

      <section className="partners-section py-5 bg-light" aria-labelledby="partners-heading">
        <Container>
          <Row>
            <Col>
              <h2 id="partners-heading">{t('partners.title')}</h2> {/* Заголовок с переводом */}
              <p>{t('partners.description')}</p> {/* Описание с переводом */}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <img src={require('../../icon/Nomad game.png')} alt={t('partners.title')} className="img-fluid mb-4" />
            </Col>
            <Col md={4}>
              <img src={require('../../icon/Barys.png')} alt="Barys" className="img-fluid mb-4" />
            </Col>
            <Col md={4}>
              <img src={require('../../icon/Airport.png')} alt="Airport" className="img-fluid mb-4" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PartnersSection;
