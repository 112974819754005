import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // Импортируем useTranslation
import './BusRentalSection.css';  // Подключаем файл со стилями

const BusRentalSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBus, setSelectedBus] = useState(null);
  const { t } = useTranslation(); // Используем i18n для перевода

  const buses = [
    {
      title: t('busRental.busDetails.cityBus.title'),
      imgSrc: "https://inbusiness.kz/uploads/2023-6/UjQOhfHZ.webp",
      description: t('busRental.busDetails.cityBus.description'),
      details: t('busRental.busDetails.cityBus.details')
    },
    {
      title: t('busRental.busDetails.touristBus.title'),
      imgSrc: "https://fotobus.msk.ru/photo/29/44/11/2944112.jpg",
      description: t('busRental.busDetails.touristBus.description'),
      details: t('busRental.busDetails.touristBus.details')
    },
    {
      title: t('busRental.busDetails.minibus.title'),
      imgSrc: "https://fotobus.msk.ru/photo/29/87/43/2987432.jpg",
      description: t('busRental.busDetails.minibus.description'),
      details: t('busRental.busDetails.minibus.details')
    }
  ];

  const handleShowModal = (bus) => {
    setSelectedBus(bus);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBus(null);
  };

  return (
    <section className="bus-rental-section py-5 bg-light" aria-labelledby="bus-rental-heading">
      <Container>
        <Row>
          <Col>
            <h2 id="bus-rental-heading">{t('busRental.title')}</h2>  {/* Заголовок с переводом */}
            <p>{t('busRental.description')}</p>  {/* Описание с переводом */}
          </Col>
        </Row>
        <Row>
          {buses.map((bus, index) => (
            <Col md={4} key={index}>
              <Card className="mb-4">
                <Card.Img variant="top" src={bus.imgSrc} alt={`${bus.title} для аренды`} />
                <Card.Body>
                  <Card.Title>{bus.title}</Card.Title>
                  <Card.Text>{bus.description}</Card.Text>
                  <Button variant="primary" onClick={() => handleShowModal(bus)}>{t('busRental.viewDetails')}</Button> {/* Кнопка с переводом */}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {selectedBus && (
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>{selectedBus.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{selectedBus.details}</p>
              <h5>{t('busRental.availableModels')}</h5>
              <ul>
                <li>Модель 1</li>
                <li>Модель 2</li>
                <li>Модель 3</li>
              </ul>
              <h5>{t('busRental.form.fillOut')}</h5>
              <Form>
                <Form.Group controlId="companyName">
                  <Form.Label>{t('busRental.form.companyName')}</Form.Label>
                  <Form.Control type="text" placeholder={t('busRental.form.companyNamePlaceholder')} />
                </Form.Group>
                <Form.Group controlId="contactPerson">
                  <Form.Label>{t('busRental.form.contactPerson')}</Form.Label>
                  <Form.Control type="text" placeholder={t('busRental.form.contactPersonPlaceholder')} />
                </Form.Group>
                <Form.Group controlId="contactNumber">
                  <Form.Label>{t('busRental.form.contactNumber')}</Form.Label>
                  <Form.Control type="tel" placeholder={t('busRental.form.contactNumberPlaceholder')} />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>{t('busRental.form.email')}</Form.Label>
                  <Form.Control type="email" placeholder={t('busRental.form.emailPlaceholder')} />
                </Form.Group>
                <Form.Group controlId="comments">
                  <Form.Label>{t('busRental.form.comments')}</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder={t('busRental.form.commentsPlaceholder')} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>{t('busRental.form.close')}</Button>
              <Button variant="primary">{t('busRental.form.submit')}</Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    </section>
  );
};

export default BusRentalSection;
