import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import './AboutVideoSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import videoThumbnail from '../../assets/fotoPark/DSC02208.jpg'; // Импорт локального изображения
import { useTranslation } from 'react-i18next';

const AboutVideoSection = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container className="about-video-section mb-4">
      <Row>
        <Col md={6}>
          <div className="about-block">
          <h1>{t("about_us")}</h1>
          <p style={{ fontSize: "20px" }}>{t("about_description")}</p>
          </div>
        </Col>
        <Col md={6}>
          <div className="video-block" onClick={handleShow}>
          <img src={videoThumbnail} alt={t("video_about_us")} className="video-thumbnail" />
          <Button variant="primary" className="video-button" onClick={handleShow}>
              <FontAwesomeIcon icon={faPlay} />
            </Button>
          </div>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
        <Modal.Title>{t("video_about_us")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video-container">
            <video width="100%" controls>
              <source src={require('../../assets/fotoPark/50 лет.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AboutVideoSection;
