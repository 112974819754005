import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import bus1 from "./assets/IVECO Crossway.jpg"; // пример путей к изображениям
import bus2 from "./assets/Астана, Irisbus Citelis 18M.jpg";
import bus3 from "./assets/Астана, Yutong ZK6128HG.jpg";

// Файлы с переводами
const resources = {
  ru: {
    translation: {
      businessPageTitle:
        "Бизнес — Аренда и Реклама на Автобусах | Автобусный Парк №1",
      our_fleet: "Наш автопарк",
      search: "Поиск",
      company: "Предприятие",
      forPassengers: "Пассажирам",
      business: "Бизнесу",
      pressCenter: "Пресс-служба",
      contacts: "Контакты",
      future: "Двигаемся в будущее с заботой о природе",
      future_desc:
        "Наш автопарк оснащен современными экологически чистыми автобусами, которые минимизируют выбросы и поддерживают чистоту воздуха в городе.",
      safety: "Ваша безопасность — наш приоритет",
      safety_desc:
        "Мы гарантируем безопасность на каждом маршруте, благодаря строгим стандартам обслуживания и модернизированным системам безопасности.",
      mobility: "Быстрое и удобное передвижение по городу",
      mobility_desc: "Скачайте наше приложение и планируйте поездки легко.",
      news: "Новости и объявления",

      about_us: "О нас",
      about_description:
        "Автобусный парк №1 — крупнейший оператор общественного транспорта, предоставляющий услуги по перевозке пассажиров. Мы стремимся к высокому качеству обслуживания и комфорту для наших пассажиров.",
      video_about_us: "Видео о нас",
      our_history: "Наша история",

      partners_description:
        "Мы гордимся сотрудничеством с ведущими организациями, которые помогают нам развивать транспортную инфраструктуру и улучшать сервис.",

      footer_company: "КОМПАНИЯ",
      footer_activity: "Деятельность",
      footer_leadership: "Руководство",
      footer_partners: "Партнеры",
      footer_vacancies: "Вакансии",
      footer_best_employees: "Лучшие сотрудники",
      footer_compliance_officer: "Комплаенс-офицер",

      structor_company: "Структура Компании",
      board_of_directors: "Совет Директоров",
      purchase_plan: "План закупок",
      partners_title: "Наши партнеры",

      footer_passengers: "ПАССАЖИРАМ",
      footer_routes: "Маршруты",
      footer_transport_cards: "Транспортные карты",
      footer_recharge_points: "Пункты пополнения транспортных карт",
      footer_rules: "Правила перевозки пассажиров и багажа в столице",

      footer_info: "ИНФОРМАЦИЯ",
      footer_contacts: "Контакты",
      footer_news: "Новости",
      footer_recharge: "Пункты пополнения",

      contact_us: {
        title: "Свяжитесь с нами",
        description: "У вас есть вопросы? Мы готовы помочь вам в любое время.",
        buttonText: "Связаться с нами",
      },
      contactss: {
        breadcrumb: {
          home: "Главная",
          contacts: "Контакты",
        },
        title: "Контакты",
        companyName: "АО «Автобусный парк №1»",
        addressLabel: "Адрес головного офиса",
        address: "г. Астана, ул. Александра Пушкина, 37/1",
        stopLabel: "Остановка",
        stop: "АО 'Автобусный парк №1', автобусы - 11, 18, 30, 41",
        phoneLabel: "Телефон диспетчерского центра",
        phone: "54 16 45, +7 777 781 28 72",
        workingHoursLabel: "Часы работы",
        workingHours:
          "Пн. - Пт. с 8.30 до 18.00 (обеденный перерыв с 13:00 до 14:30)",
        emailLabel: "Электронная почта",
        email: "info@avtopark1.kz",
      },

      intro: {
        heading: "Сотрудничество с Автобусным Парком №1",
        paragraph:
          'Добро пожаловать на страницу "Бизнесу". Мы предлагаем аренду автобусов и возможности для размещения рекламы на транспорте. С нами вы получите надежного партнера для вашего бизнеса.',
      },
      partners: {
        title: "Наши Партнеры",
        description:
          "Мы сотрудничаем с ведущими компаниями. Присоединяйтесь к числу наших успешных партнеров.",
      },

      busRental: {
        title: "Аренда Автобусов",
        description:
          "Мы предлагаем широкий выбор автобусов для аренды, подходящих для любых нужд вашего бизнеса.",
        viewDetails: "Подробнее",
        busDetails: {
          cityBus: {
            title: "Городской автобус",
            description: "Идеально подходит для перевозки сотрудников.",
            details:
              "Мы рады предложить вам городской автобус для аренды. Этот тип автобуса идеально подходит для ежедневных поездок по городу и перевозки сотрудников.",
          },
          touristBus: {
            title: "Туристический автобус",
            description: "Отличный выбор для туристических поездок.",
            details:
              "Туристические автобусы идеально подходят для дальних поездок и экскурсий. Внутри автобусов созданы все условия для комфортного путешествия.",
          },
          minibus: {
            title: "Микроавтобус",
            description: "Компактный транспорт для малых групп.",
            details:
              "Микроавтобусы предоставляются для небольших групп пассажиров. Идеально подходят для корпоративных мероприятий и частных поездок.",
          },
        },
        form: {
          companyName: "Наименование компании",
          contactPerson: "Контактное лицо",
          contactNumber: "Контактный номер телефона",
          email: "Email",
          comments: "Комментарии",
          submit: "Отправить заявку",
          close: "Закрыть",
        },
      },
      lostAndFound: {
        title: "Потерянные и найденные вещи",
        itemName: "Название потерянной вещи",
        routeNumber: "Укажите номер маршрута",
        paymentProof: "Предоставьте форму оплаты",
        itemDescription: "Описание",
        contactInfo: "Контактная информация",
        invalidPhone: "Введите корректный номер телефона.",
        submit: "Отправить",
        close: "Закрыть",
        helpLink: "Как обратиться за помощью?",
        itemNamePlaceholder: "Введите название вещи",
        routeNumberPlaceholder: "Введите номер маршрута",
        paymentProofPlaceholder: "Загрузите подтверждение оплаты",
        itemDescriptionPlaceholder: "Опишите потерянную вещь",
        contactInfoPlaceholder: "Введите ваши контактные данные",
      },
      advertising: {
        title: "Реклама на Автобусах",
        description:
          "Используйте наши автобусы как мобильные рекламные площадки для вашего бизнеса.",
        adTypes: {
          onBoard: {
            title: "Реклама на бортах",
            description: "Максимальная видимость на улицах города.",
            details: [
              "Размещение рекламы на более 700 автобусах, Вас заметят более 500 тысяч пассажиров и более 350 тыс. водителей автомобилей.",
              "Реклама размещается по обеим сторонам автобуса на разных языках.",
            ],
          },
          inside: {
            title: "Реклама внутри автобуса",
            description:
              "Привлеките внимание пассажиров внутри транспортного средства.",
            details: [
              "Рекламные постеры над окнами в автобусах г. Астана. Размер каждой панели: от 152 см * 38 см до 200 см * 50 см.",
              "Реклама на видеомониторах в Автобусах Астаны. Мониторы форматом: 22 см х 40 см, диагональ - 46 см.",
              "Количество мониторов в каждом автобусе: по 2 монитора в 12-метровых автобусах и по 4 монитора в 18-метровых автобусах.",
            ],
          },
        },
        form: {
          companyName: "Наименование компании",
          contactPerson: "Контактное лицо",
          contactNumber: "Контактный номер телефона",
          email: "Email",
          submit: "Отправить заявку",
          close: "Закрыть",
        },
        inquiryText:
          "Заинтересованы? Мы готовы обсудить детали и предложить оптимальные условия для вашего бизнеса. Пожалуйста, заполните форму ниже, и мы свяжемся с вами для дальнейшего сотрудничества.",
      },

      park: {
        title: "Сегодня Автобусный парк №1 это:",
        subtitle: "более 2000 сотрудников",
        employees_70: "70% сотрудников -",
        bus_drivers: "водители автобусов",
        daily_transport: "Ежедневно Автобусный парк №1 перевозит",
        passengers_436k: "более 436 000 пассажиров",
        daily_work: "На линии ежедневно работают",
        drivers_700: "более 700 водителей",
      },
      fleet: [
        {
          id: 1,
          image: bus1,
          title: "Iveco Crossway Le City",
          description:
            "Современный городской автобус с низким уровнем выбросов и высоким уровнем комфорта.",
          year: "Закуплен в 2017 году",
        },
        {
          id: 2,
          image: bus2,
          title: "Iveco Citelis Irisbus",
          description:
            "Надежный автобус для городских маршрутов с высокой топливной эффективностью.",
          year: "Закуплен в 2014 году",
        },
        {
          id: 3,
          image: bus3,
          title: "Yutong ZK6128HG",
          description:
            "Автобус с просторным салоном и улучшенной маневренностью для городских улиц.",
          year: "Закуплен в 2023 году",
        },
        {
          id: 4,
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVCqk9UC4jTqO8DyBDv03KaxSqYii7cJwqnQ&s",
          title: "Yutong ZK6180BEVG",
          description:
            "Автобус с просторным салоном и улучшенной маневренностью для городских улиц.",
          year: "Закуплен в 2024 году",
        },
      ],
    },
  },
  // Казахский язык
  kz: {
    translation: {
      businessPageTitle:
        "Бизнес — Автобустарды жалға беру және жарнама | №1 Автобус парк",
      our_fleet: "Біздің автопарк",
      search: "Іздеу",
      company: "Кәсіпорын",
      forPassengers: "Жолаушыларға",
      business: "Бизнеске",
      pressCenter: "Баспасөз қызметі",
      contacts: "Байланыс",
      future: "Болашаққа табиғатты қорғаумен бірге қадам басамыз",
      future_desc:
        "Біздің автопарк заманауи экологиялық таза автобустармен жабдықталған, олар шығарындыларды азайтып, қаланың ауасын таза ұстайды.",
      safety: "Сіздің қауіпсіздігіңіз – біздің басты міндетіміз",
      safety_desc:
        "Қатаң қызмет көрсету стандарттары мен жаңартылған қауіпсіздік жүйелері арқылы әр бағытта қауіпсіздікті қамтамасыз етеміз.",
      mobility: "Қала ішінде жылдам және ыңғайлы қозғалыс",
      mobility_desc:
        "Біздің қосымшаны жүктеп алып, сапарларыңызды оңай жоспарлаңыз.",
      news: "Жаңалықтар мен хабарламалар",

      about_us: "Біз туралы",
      about_description:
        "№1 Автобус паркі – жолаушылар тасымалдау қызметтерін көрсететін ең ірі қоғамдық көлік операторы. Біз жоғары сапалы қызмет пен жолаушылар үшін жайлылыққа ұмтыламыз.",
      video_about_us: "Біз туралы бейне",

      structor_company: "Компанияның құрылымы",
      board_of_directors: "Директорлар кеңесі",
      our_history: "Біздің тарих",
      purchase_plan: "Сатып алу жоспары",
      partners_title: "Біздің серіктестеріміз",
      partners_description:
        "Біз көлік инфрақұрылымын дамытуға және қызмет көрсетуді жақсартуға көмектесетін жетекші ұйымдармен ынтымақтастықпен мақтан тұтамыз.",

      footer_company: "КОМПАНИЯ",
      footer_activity: "Қызмет",
      footer_leadership: "Басшылық",
      footer_partners: "Серіктестер",
      footer_vacancies: "Бос жұмыс орындары",
      footer_best_employees: "Үздік қызметкерлер",
      footer_compliance_officer: "Комплаенс-офицер",

      footer_passengers: "ЖОЛАУШЫЛАРҒА",
      footer_routes: "Маршруттар",
      footer_transport_cards: "Көлік карталары",
      footer_recharge_points: "Көлік карталарын толықтыру орындары",
      footer_rules: "Елордадағы жолаушылар мен багажды тасымалдау ережелері",

      footer_info: "АҚПАРАТ",
      footer_contacts: "Байланыстар",
      footer_news: "Жаңалықтар",
      footer_recharge: "Толықтыру пункттері",

      intro: {
        heading: "№1 Автобус паркімен ынтымақтастық",
        paragraph:
          '"Бизнес үшін" бетіне қош келдіңіз. Біз автобус жалға алу және көлікте жарнама орналастыру мүмкіндіктерін ұсынамыз. Бізбен бірге сіз өз бизнесіңіз үшін сенімді серіктес табасыз.',
      },
      partners: {
        title: "Біздің серіктестер",
        description:
          "Біз жетекші компаниялармен серіктестік жасаймыз. Біздің сәтті серіктестеріміз қатарына қосылыңыз.",
      },
      busRental: {
        title: "Автобустарды жалға алу",
        description:
          "Біз сіздің бизнесіңізге қажетті барлық қажеттіліктерді қанағаттандыратын автобустарды жалға беру қызметін ұсынамыз.",
        busDetails: {
          cityBus: {
            title: "Қалалық автобус",
            description: "Қызметкерлерді тасымалдауға өте ыңғайлы.",
            details:
              "Біз сізге қалалық автобусты жалға беру қызметін ұсынамыз. Бұл автобустар қала ішіндегі күнделікті жол жүру және қызметкерлерді тасымалдауға өте қолайлы.",
          },
          touristBus: {
            title: "Туристік автобус",
            description: "Туристік сапарлар үшін керемет таңдау.",
            details:
              "Туристік автобустар ұзақ сапарлар мен экскурсиялар үшін өте қолайлы. Автобустар ішінде ыңғайлы саяхат үшін барлық жағдайлар жасалған.",
          },
          minibus: {
            title: "Микроавтобус",
            description: "Шағын топтар үшін ыңғайлы көлік.",
            details:
              "Микроавтобустар шағын топтар үшін қызмет көрсетіледі. Корпоративтік іс-шаралар мен жеке сапарлар үшін өте қолайлы.",
          },
        },
        form: {
          companyName: "Компанияның атауы",
          contactPerson: "Байланыс тұлғасы",
          contactNumber: "Байланыс нөмірі",
          email: "Электрондық пошта",
          comments: "Пікірлер",
          submit: "Өтініш жіберу",
          close: "Жабу",
        },
      },
      advertising: {
        title: "Автобустардағы жарнама",
        description:
          "Біздің автобустарымызды бизнесіңіз үшін мобильді жарнамалық алаңдар ретінде пайдаланыңыз.",
        adTypes: {
          onBoard: {
            title: "Автобустың бортында жарнама",
            description: "Қаланың көшелерінде максималды көрініс.",
            details: [
              "700-ден астам автобустарда жарнама орналастыру, 500 мыңнан астам жолаушы мен 350 мыңнан астам жүргізуші сізді байқайды.",
              "Жарнама автобустың екі жағында әртүрлі тілдерде орналастырылады.",
            ],
          },
          inside: {
            title: "Автобуста ішіндегі жарнама",
            description:
              "Транспорт құралы ішіндегі жолаушылардың назарын аударыңыз.",
            details: [
              "Астана қаласындағы автобустардың терезелерінің үстіндегі жарнамалық постерлер. Әр панельдің өлшемі: 152 см * 38 см-ден 200 см * 50 см-ге дейін.",
              "Астана қаласындағы автобустардағы бейнемониторлар. Мониторлардың өлшемі: 22 см х 40 см, диагональ - 46 см.",
              "Әр автобустың ішінде 12-метрлік автобустарда 2 монитордан, 18-метрлік автобустарда 4 монитордан бар.",
            ],
          },
        },
        form: {
          companyName: "Компанияның атауы",
          contactPerson: "Байланыс тұлғасы",
          contactNumber: "Байланыс нөмірі",
          email: "Электрондық пошта",
          submit: "Өтініш жіберу",
          close: "Жабу",
        },
        inquiryText:
          "Қызықтырды ма? Біз сіздің бизнесіңіз үшін ең тиімді шарттарды талқылауға дайынбыз. Төмендегі форманы толтырыңыз, біз сізбен байланысқа шығамыз.",
      },

      lostAndFound: {
        title: "Жоғалған және табылған заттар",
        itemName: "Жоғалған заттың атауы",
        routeNumber: "Маршрут нөмірін көрсетіңіз",
        paymentProof: "Төлемді растау",
        itemDescription: "Сипаттама",
        contactInfo: "Байланыс ақпараты",
        invalidPhone: "Дұрыс телефон нөмірін енгізіңіз.",
        submit: "Жіберу",
        close: "Жабу",
        helpLink: "Қалай көмек сұрауға болады?",
        itemNamePlaceholder: "Заттың атын енгізіңіз",
        routeNumberPlaceholder: "Маршрут нөмірін енгізіңіз",
        paymentProofPlaceholder: "Төлемді растайтын құжатты жүктеңіз",
        itemDescriptionPlaceholder: "Жоғалған затты сипаттаңыз",
        contactInfoPlaceholder: "Байланыс ақпаратын енгізіңіз",
      },
      contact_us: {
        title: "Бізбен байланысыңыз",
        description:
          "Сұрақтарыңыз бар ма? Біз сізге кез келген уақытта көмектесуге дайынбыз.",
        buttonText: "Бізбен байланысу",
      },
      contactss: {
        breadcrumb: {
          home: "Басты бет",
          contacts: "Байланыстар",
        },
        title: "Байланыстар",
        companyName: "«№1 Автобус паркі» АҚ",
        addressLabel: "Бас кеңсенің мекенжайы",
        address: "Астана қ., Александр Пушкин к., 37/1",
        stopLabel: "Автобус аялдамасы",
        stop: "«№1 Автобус паркі» АҚ, автобустар - 11, 18, 30, 41",
        phoneLabel: "Диспетчерлік орталықтың телефоны",
        phone: "54 16 45, +7 777 781 28 72",
        workingHoursLabel: "Жұмыс уақыты",
        workingHours: "Дс. - Жм. 8.30 - 18.00 (түскі үзіліс 13:00 - 14:30)",
        emailLabel: "Электрондық пошта",
        email: "info@avtopark1.kz",
      },

      park: {
        title: "Бүгін Автобус паркі №1:",
        subtitle: "2000-нан астам қызметкер",
        employees_70: "Қызметкерлердің 70% -",
        bus_drivers: "автобус жүргізушілері",
        daily_transport: "Күн сайын №1 автобус паркі тасымалдайды",
        passengers_436k: "436 000-нан астам жолаушыны",
        daily_work: "Күн сайын желіде жұмыс істейді",
        drivers_700: "700-ден астам жүргізуші",
      },

      fleet: [
        {
          id: 1,
          image: bus1,
          title: "Iveco Crossway Le City",
          description:
            "Төмен шығарындылары бар заманауи қалалық автобус, жайлылық деңгейі жоғары.",
          year: "2017 жылы сатып алынды",
        },
        {
          id: 2,
          image: bus2,
          title: "Iveco Citelis Irisbus",
          description:
            "Қалалық маршруттарға арналған сенімді автобус, жанармай тиімділігі жоғары.",
          year: "2014 жылы сатып алынды",
        },
        {
          id: 3,
          image: bus3,
          title: "Yutong ZK6128HG",
          description:
            "Кең салоны және жақсартылған маневрлік қабілеті бар автобус.",
          year: "2023 жылы сатып алынды",
        },
        {
          id: 4,
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVCqk9UC4jTqO8DyBDv03KaxSqYii7cJwqnQ&s",
          title: "Yutong ZK6180BEVG",
          description:
            "Кең салоны және жақсартылған маневрлік қабілеті бар автобус.",
          year: "2024 жылы сатып алынды",
        },
      ],
    },
  },
};

i18n
  .use(LanguageDetector) // Определение языка
  .use(initReactI18next) // Интеграция с React
  .init({
    resources,
    fallbackLng: "ru", // Язык по умолчанию
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
