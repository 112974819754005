import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from './GlobalStyles.js'; // Импортируем глобальные стили
import Header from './Components/Header/Header.js';
import Company from './Pages/Company/Company';
import CompanyDetails from './Pages/Company/CompanyDetails';
import ForPassengers from './Pages/ForPassengers/ForPassengers';
import Business from './Pages/Business/Business';
import PressCenter from './Pages/PressCenter/PressCenter';
import Contacts from './Pages/Contacts/Contacts';
import Home from './Pages/Home/Home';
import Footer from './Components/Footer/Footer';
import NewsDetail from './Components/News/NewsDetail'; // Импортируем NewsDetail
import FoundItemsPage from '../src/Pages/FoundItemsPage/FoundItemsPage.js'; // Импорт страницы
import './fonts.css';
import { SpeedInsights } from '@vercel/speed-insights/react';
import "./i18n";




function App() {
  return (
    <Router>
      <GlobalStyle /> {/* Применяем глобальные стили */}
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route path="/company/:companyId" element={<CompanyDetails />} />
          <Route path="/for-passengers" element={<ForPassengers />} />
          <Route path="/found-items" element={<FoundItemsPage />} />
          <Route path="/business" element={<Business />} />
          <Route path="/press-center" element={<PressCenter />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/news/:id" element={<NewsDetail />} /> {/* Добавляем маршрут для NewsDetail */}
        </Routes>
        <SpeedInsights />
      </div>
      <Footer/>
    </Router>
  );
}

export default App;
