import React, { useState, useTransition } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import logo from "../Header/Group 824.png";
import "./MobileHeader.css"; // Подключение файла стилей
import SEO from "../SEO"; // Импортируем SEO компонент
import { useTranslation } from "react-i18next";

const MobileHeader = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isPending, startTransition] = useTransition(); // Use the hook here
  const { t } = useTranslation(); // Достаем i18n
  const { i18n } = useTranslation(); // Достаем i18n

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <SEO
        title="Общественный транспорт Астана - Автобусный парк №1 | Комфорт и надежность"
        description="Автобусный парк №1 в Астане предлагает удобные и экологически чистые автобусы, широкий выбор маршрутов и аренду транспорта для частных и корпоративных нужд."
        keywords="автобусный парк Астана, общественный транспорт Астана, аренда автобусов Астана, автобусные маршруты Астана, экологичные автобусы, городской транспорт, прокат автобусов, комфортные автобусы, транспортные услуги Астана"
      />

      <Navbar
        fixed="top"
        expand="md"
        style={{ backgroundColor: "#09F", height: "80px" }}
        variant="dark"
      >
        <Container>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={toggleMobileMenu}
          />
          <Navbar.Brand href="/" className="mx-auto">
            <img
              src={logo}
              height={25}
              width="auto"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>

      {isMobileMenuOpen && (
        <div className="mobile-menu-overlay" onClick={closeMobileMenu}></div>
      )}

      <div className={`mobile-menu ${isMobileMenuOpen ? "active" : ""}`}>
        <div className="mobile-header-top">
          <Nav className="flex-column">
            <Nav.Link href="/company" className="custom-nav-link">
              {t("company")}
            </Nav.Link>
            <Nav.Link href="/for-passengers" className="custom-nav-link">
              {t("forPassengers")}
            </Nav.Link>
            <Nav.Link href="/business" className="custom-nav-link">
              {t("business")}
            </Nav.Link>
            <Nav.Link href="/press-center" className="custom-nav-link">
              {t("pressCenter")}
            </Nav.Link>
            <Nav.Link href="/contacts" className="custom-nav-link">
              {t("contacts")}
            </Nav.Link>
          </Nav>
          <div className="language-switcher">
            <Button
              variant="link"
              className="language-btn"
              onClick={() => changeLanguage("kz")}
            >
              ҚАЗ
            </Button>
            |
            <Button
              variant="link"
              className="language-btn"
              onClick={() => changeLanguage("ru")}
            >
              РУС
            </Button>
          </div>
        </div>
        <div className="social-icons">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={require("../../icon/3932196.png")} alt="Facebook" />
          </a>
          <a
            href="https://www.instagram.com/avtopark_01/?igsh=MW55cTN3Zm9hazB1NA%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={require("../../icon/5618785.png")} alt="Instagram" />
          </a>
          <a
            href="https://www.tiktok.com/@avtopark_01_astana"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={require("../../icon/3046114.png")} alt="TikTok" />
          </a>
        </div>
      </div>

      <div className="mobile-content">
        {/* Контент сайта для мобильной версии */}
      </div>
    </>
  );
};

export default MobileHeader;
