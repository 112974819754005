import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // Импортируем useTranslation
import './AdvertisingSection.css';  // Подключаем файл со стилями

const AdvertisingSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const { t } = useTranslation(); // Используем i18n для перевода

  const handleShowModal = (ad) => {
    setSelectedAd(ad);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAd(null);
  };

  return (
    <section className="advertising-section py-5" aria-labelledby="advertising-heading">
      <Container>
        <Row>
          <Col>
            <h2 id="advertising-heading">{t('advertising.title')}</h2> {/* Заголовок с переводом */}
            <p>{t('advertising.description')}</p> {/* Описание с переводом */}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Img variant="top" src="https://sharaina.kz/thumb/2/4VjA4tksTsP9WkwsNUuKUA/r/d/avtobus.jpg" alt={t('advertising.adTypes.onBoard.title')} />
              <Card.Body>
                <Card.Title>{t('advertising.adTypes.onBoard.title')}</Card.Title>
                <Card.Text>{t('advertising.adTypes.onBoard.description')}</Card.Text>
                <Button variant="primary" onClick={() => handleShowModal('onBoard')}>{t('advertising.adTypes.onBoard.title')}</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Img variant="top" src="https://optim.tildacdn.com/tild3231-3939-4138-b564-313435346465/-/resize/640x/-/format/webp/noroot.jpg" alt={t('advertising.adTypes.inside.title')} />
              <Card.Body>
                <Card.Title>{t('advertising.adTypes.inside.title')}</Card.Title>
                <Card.Text>{t('advertising.adTypes.inside.description')}</Card.Text>
                <Button variant="primary" onClick={() => handleShowModal('inside')}>{t('advertising.adTypes.inside.title')}</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedAd === 'onBoard' ? t('advertising.adTypes.onBoard.title') : t('advertising.adTypes.inside.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedAd === 'onBoard' ? (
              <>
                {t('advertising.adTypes.onBoard.details', { returnObjects: true }).map((detail, index) => (
                  <p key={index}>{detail}</p>
                ))}
              </>
            ) : (
              <>
                {t('advertising.adTypes.inside.details', { returnObjects: true }).map((detail, index) => (
                  <p key={index}>{detail}</p>
                ))}
              </>
            )}

            <hr />

            <p>{t('advertising.inquiryText')}</p>

            <Form>
              <Form.Group controlId="companyName">
                <Form.Label>{t('advertising.form.companyName')}</Form.Label>
                <Form.Control type="text" placeholder={t('advertising.form.companyName')} />
              </Form.Group>
              <Form.Group controlId="contactPerson">
                <Form.Label>{t('advertising.form.contactPerson')}</Form.Label>
                <Form.Control type="text" placeholder={t('advertising.form.contactPerson')} />
              </Form.Group>
              <Form.Group controlId="contactNumber">
                <Form.Label>{t('advertising.form.contactNumber')}</Form.Label>
                <Form.Control type="tel" placeholder={t('advertising.form.contactNumber')} />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>{t('advertising.form.email')}</Form.Label>
                <Form.Control type="email" placeholder={t('advertising.form.email')} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              {t('advertising.form.close')}
            </Button>
            <Button variant="primary">
              {t('advertising.form.submit')}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default AdvertisingSection;
