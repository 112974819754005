import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './FleetSection.css';

const FleetSection = () => {
  const { t } = useTranslation();
  const buses = t("fleet", { returnObjects: true });

  return (
    <Container className="fleet-section-container">
      <h2 className="text-center m-4">{t("our_fleet")}</h2>
      <Row className="justify-content-center d-flex flex-wrap">
        {buses.map((bus, index) => (
          <Col key={index} className="fleet-col">
            <Card className="fleet-card">
              <Card.Img style={{height:200}} variant="top" src={bus.image} alt={bus.title} />
              <Card.Body>
                <Card.Title>{bus.title}</Card.Title>
                <Card.Text>{bus.description}</Card.Text>
                <Card.Footer className="text-muted">{bus.year}</Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default FleetSection;
