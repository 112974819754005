import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import backgroundImage from '../../assets/Contact.jpg'; // Фоновое изображение

class Contacts extends Component {
    componentDidMount() {
        const script = document.createElement('script');
        script.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
        script.async = true;
        script.onload = () => {
            window.ymaps.ready(() => {
                const map = new window.ymaps.Map("map", {
                    center: [51.170059, 71.477234],
                    zoom: 16
                });

                const placemark = new window.ymaps.Placemark([51.170059, 71.477234], {
                    hintContent: this.props.t('contactss.companyName'),
                    balloonContent: this.props.t('contactss.address')
                });

                map.geoObjects.add(placemark);
            });
        };
        document.body.appendChild(script);
    }

    render() {
        const { t } = this.props; // Доступ к функции перевода

        return (
            <div 
                style={{ 
                    marginTop: '0px',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    minHeight: '100vh',
                    padding: '50px 0',
                    fontFamily: 'Refrigerator Deluxe, sans-serif',
                    fontSize: '18px',
                    color: '#333',
                    lineHeight: '1.6',
                }}
            >
                <Container>
                    <Row>
                        <Col>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/" style={{ color: '#007bff', textDecoration: 'none' }}>
                                            {t('contactss.breadcrumb.home')}
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ color: '#007bff' }}>
                                        {t('contactss.breadcrumb.contacts')}
                                    </li>
                                </ol>
                            </nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <h1 style={{ fontSize: '48px', color: '#09f', marginBottom: '20px' }}>{t('contactss.title')}</h1>
                            <p style={{ fontSize: '20px' }}>
                                <strong>{t('contactss.companyName')}</strong>
                            </p>
                            <p>
                                <strong>{t('contactss.addressLabel')}:</strong> {t('contactss.address')}
                            </p>
                            <p>
                                <strong>{t('contactss.stopLabel')}:</strong> {t('contactss.stop')}
                            </p>
                            <p>
                                <strong>{t('contactss.phoneLabel')}:</strong> {t('contactss.phone')}
                            </p>
                            <p>
                                <strong>{t('contactss.workingHoursLabel')}:</strong> {t('contactss.workingHours')}
                            </p>
                            <p>
                                <strong>{t('contactss.emailLabel')}:</strong> 
                                <a href="mailto:info@1ap.kz" style={{ color: '#007bff', textDecoration: 'none' }}>
                                    {t('contactss.email')}
                                </a>
                            </p>
                        </Col>
                        <Col md={6}>
                            <div id="map" style={{
                                width: '100%',
                                height: '600px',
                                border: '5px solid #FF0',
                                borderRadius: '20px',
                                overflow: 'hidden'
                            }}></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withTranslation()(Contacts);
