import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import InfoBlock from '../../Components/InfoBlock';
import LostAndFoundModal from '../../Components/LostAndFoundModal';
import infoData from '../../data/infoItems.json';
import icon1 from '../../icon/2751253.png';
import icon2 from '../../icon/2984172.png';
import icon3 from '../../icon/2154936.png';
import icon4 from '../../icon/916884.png';
import './ForPassengers.css';

class ForPassengers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleShowModal = (index) => {
    // Открываем модальное окно только если это первый элемент (индекс 0)
    if (index === 0) {
      this.setState({ showModal: true });
    }
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { showModal } = this.state;
    const { i18n } = this.props;
    const currentLang = i18n.language || 'ru';
    const icons = [icon1, icon2, icon3, icon4];

    return (
      <Container className="for-passengers-container">
        {infoData.infoItems[currentLang]?.map((item, index) => (
          <InfoBlock
            key={index}
            title={item.title}
            description={item.description}
            icon={icons[index]}
            className="custom-row"
            onClick={() => this.handleShowModal(index)}
          />
        ))}

        {/* Показываем модальное окно, только если showModal === true */}
        {showModal && (
          <LostAndFoundModal
            show={showModal}
            handleClose={this.handleCloseModal}
          />
        )}
      </Container>
    );
  }
}

export default withTranslation()(ForPassengers);
