import React from 'react';
import { Helmet } from 'react-helmet';
import IntroSection from '../../Components/IntroSection/IntroSection';
import BusRentalSection from '../../Components/BusRentalSection/BusRentalSection';
import AdvertisingSection from '../../Components/AdvertisingSection/AdvertisingSection';
import PartnersSection from '../../Components/PartnersSection/PartnersSection';
import ContactSection from '../../Components/ContactSection/ContactSection';
import { useTranslation } from 'react-i18next';

const Business = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('businessPageTitle')}</title>
        <meta name="description" content="Страница для бизнеса с предложениями аренды автобусов и размещения рекламы на автобусах от Автобусного Парка №1." />
        <link rel="canonical" href="https://ваш-домен.com/business" />
      </Helmet>
      <IntroSection/>
      <BusRentalSection/>
      <AdvertisingSection/>
      <PartnersSection/>
      <ContactSection/>
    </>
  );
}

export default Business;
