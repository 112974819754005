import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // Импортируем i18n

const LostAndFoundModal = ({ show, handleClose }) => {
  const [itemName, setItemName] = useState('');
  const [routeNumber, setRouteNumber] = useState('');
  const [paymentProof, setPaymentProof] = useState(null);
  const [itemDescription, setItemDescription] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const { t } = useTranslation(); // Получаем функцию перевода

  // Функция для проверки корректности введенного номера телефона
  const validatePhoneNumber = (number) => /^[+]?[0-9]{10,15}$/.test(number);

  // Функция для проверки валидности формы
  const validateForm = useCallback(() => {
    setIsFormValid(
      itemName.trim() !== '' &&
      routeNumber.trim() !== '' &&
      paymentProof !== null &&
      itemDescription.trim() !== '' &&
      validatePhoneNumber(contactInfo)
    );
  }, [itemName, routeNumber, paymentProof, itemDescription, contactInfo]);

  // Следим за изменением данных формы и проверяем валидацию
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  // Обработчики изменений
  const handleItemNameChange = (e) => setItemName(e.target.value);
  const handleRouteNumberChange = (e) => setRouteNumber(e.target.value);
  const handlePaymentProofChange = (e) => setPaymentProof(e.target.files[0]);
  const handleItemDescriptionChange = (e) => setItemDescription(e.target.value);
  const handleContactInfoChange = (e) => setContactInfo(e.target.value);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('lostAndFound.title')}</Modal.Title> {/* Заголовок с переводом */}
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3">
            <label className="form-label">{t('lostAndFound.itemName')}</label> {/* Перевод для метки */}
            <input
              type="text"
              className="form-control"
              value={itemName}
              onChange={handleItemNameChange}
              placeholder={t('lostAndFound.itemNamePlaceholder')} // Добавляем placeholder
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('lostAndFound.routeNumber')}</label>
            <input
              type="text"
              className="form-control"
              value={routeNumber}
              onChange={handleRouteNumberChange}
              placeholder={t('lostAndFound.routeNumberPlaceholder')} // Добавляем placeholder
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('lostAndFound.paymentProof')}</label>
            <input
              type="file"
              className="form-control"
              onChange={handlePaymentProofChange}
              placeholder={t('lostAndFound.paymentProofPlaceholder')} // Добавляем placeholder (для файлового инпута)
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('lostAndFound.itemDescription')}</label>
            <textarea
              className="form-control"
              value={itemDescription}
              onChange={handleItemDescriptionChange}
              placeholder={t('lostAndFound.itemDescriptionPlaceholder')} // Добавляем placeholder
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">{t('lostAndFound.contactInfo')}</label>
            <input
              type="text"
              className="form-control"
              value={contactInfo}
              onChange={handleContactInfoChange}
              placeholder={t('lostAndFound.contactInfoPlaceholder')} // Добавляем placeholder
            />
            {!validatePhoneNumber(contactInfo) && contactInfo !== '' && (
              <small className="form-text text-danger">{t('lostAndFound.invalidPhone')}</small> 
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>{t('lostAndFound.close')}</Button> {/* Кнопка закрытия */}
        <Button variant="primary" disabled={!isFormValid}>{t('lostAndFound.submit')}</Button> {/* Кнопка отправки */}
      </Modal.Footer>
    </Modal>
  );
};

export default LostAndFoundModal;
