import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';  // Импортируем useTranslation
import './IntroSection.css';  // Подключаем файл со стилями

const IntroSection = () => {
  const { t } = useTranslation(); // Используем i18n для перевода

  return (
    <section className="intro-section py-5" aria-labelledby="intro-heading">
      <Container>
        <Row>
          <Col>
            <h1 id="intro-heading">{t('intro.heading')}</h1>  {/* Перевод заголовка */}
            <p>{t('intro.paragraph')}</p>  {/* Перевод параграфа */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IntroSection;
