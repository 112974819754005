import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import "./ParkOverview.css"; // Подключаем файл стилей

// Пример импортов изображений и иконок
import slide1 from "../../assets/fotoPark/DSC05631.jpg";
import slide2 from "../../assets/fotoPark/DSC05667.jpg";
import slide3 from "../../assets/fotoPark/DSC05671.jpg";
import slide4 from "../../assets/fotoPark/DSC05672.jpg";
import slide5 from "../../assets/fotoPark/DSC05679.jpg";
import slide6 from "../../assets/fotoPark/DSC05694.jpg";
import slide7 from "../../assets/fotoPark/DSC05699.jpg";
import slide8 from "../../assets/fotoPark/DSC05704.jpg";
import slide9 from "../../assets/fotoPark/DSC05732.jpg";
import slide10 from "../../assets/fotoPark/DSC05746.jpg";
import slide11 from "../../assets/fotoPark/DSC05778.jpg";
import slide12 from "../../assets/fotoPark/DSC05792.jpg";
import icon1 from "../../icon/2311536.png";
import icon2 from "../../icon/1042311.png";
import icon3 from "../../icon/8625920.png";
import { useTranslation } from "react-i18next";

const ParkOverview = () => {
  const slides = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
    slide12,
  ]; // Пример изображений
  const { t } = useTranslation();

  return (
    <div>
      <Container className="slider-container">
        <h2 className="text-center slider-title">{t("park.title")}</h2>
        <h1 className="text-center slider-subtitle">{t("park.subtitle")}</h1>
        <Carousel className="custom-carousel" interval={3000} pause="hover">
          {slides.map((slide, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100 slider-image"
                src={slide}
                alt={`Slide ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>

      <div className="info-section">
        <Container>
          <Row className="text-center">
            <Col md={4}>
              <div className="info-box">
                <img src={icon1} alt="Icon 1" className="info-icon" />
                <div className="info-inline">
                  <p className="info-text-small">{t("park.employees_70")}</p>
                </div>
                <p className="info-text-medium">{t("park.bus_drivers")}</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="info-box">
                <img src={icon2} alt="Icon 2" className="info-icon" />
                <p className="info-text-small">{t("park.daily_transport")}</p>
                <h2 className="info-text-large">{t("park.passengers_436k")}</h2>
              </div>
            </Col>
            <Col md={4}>
              <div className="info-box">
                <img src={icon3} alt="Icon 3" className="info-icon" />
                <p className="info-text-small">{t("park.daily_work")}</p>
                <h2 className="info-text-large">{t("park.drivers_700")}</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ParkOverview;
