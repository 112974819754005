// src/Pages/Company/Company.js

import React, { useState, useEffect } from "react";
import { Container, Nav, Tab, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styles from './Company.module.css';
import partners from '../../data/partners.json';
import History from '../../Components/History/History'; // Импортируем новый компонент
import Leadership from "../../Components/Leadership/Leadership";
import VacancyCarousel from "../../Components/VacancyCarousel/VacancyCarousel";
import CompanyStructure from "../../Components/CompanyStructure/CompanyStructure";
import ComplianceOfficer from "../../Components/ComplianceOfficer/ComplianceOfficer";
import BoardOfDirectors from "../../Components/BoardOfDirectors/BoardOfDirectors";
import { useTranslation } from "react-i18next";

// Функция для динамической загрузки логотипов
const loadImage = (path) => {
    return import(`../../icon/${path}`).then(image => image.default).catch(err => console.error(err));
};

const Company = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab') === 'partners' ? 'third' : 'first';
    const {t} = useTranslation();
    const [partnersWithLogos, setPartnersWithLogos] = useState([]);

    useEffect(() => {
        const fetchLogos = async () => {
            const updatedPartners = await Promise.all(
                Object.keys(partners).map(async (key) => {
                    const logo = await loadImage(partners[key].logo);
                    return {
                        ...partners[key],
                        logo
                    };
                })
            );
            setPartnersWithLogos(updatedPartners);
        };

        fetchLogos();
    }, []);

    return (
        <Container className={styles.container}>
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className={`flex-column mt-2 ${styles.navPills}`}>
                            <Nav.Item>
                                <Nav.Link eventKey="first" className={styles.navLink}> {t("structor_company")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second" className={styles.navLink}> {t("footer_leadership")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third" className={styles.navLink}> {t("board_of_directors")} </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fourth" className={styles.navLink}> Комплаенс-офицер</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fifth" className={styles.navLink}> {t("partners_title")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sixth" className={styles.navLink}> {t("footer_vacancies")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="seventh" className={styles.navLink}> {t("footer_best_employees")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="eighth" className={styles.navLink}> {t("our_history")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="ninth" className={styles.navLink}> {t("purchase_plan")}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content className="mt-3">
                            <Tab.Pane eventKey="first">
                                <CompanyStructure/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <h2 className={styles.heading}>Руководство</h2>
                                <Leadership/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <BoardOfDirectors/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <ComplianceOfficer/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                                <h3 className={styles.heading}>Партнеры</h3>
                                <div className={styles.paragraph}>
                                    {partnersWithLogos.map((partner, index) => (
                                        <div key={index} className={styles.partnerBlock}>
                                            <img src={partner.logo} alt={partner.name} className={styles.partnerLogo} />
                                            <div className={styles.partnerText}>
                                                <h4 className={styles.partnerName}>{partner.name}</h4>
                                                <p className={styles.partnerDescription}>{partner.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth">
                                <h3 className={styles.heading}>Вакансии</h3>
                                <VacancyCarousel/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="seventh">
                                <h3 className={styles.heading}>Лучшие сотрудники</h3>
                                <p className={styles.paragraph}>Здесь можно разместить информацию о лучших сотрудниках.</p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="eighth">
                                <History /> {/* Используем новый компонент для истории */}
                            </Tab.Pane>
                            <Tab.Pane eventKey="ninth">
                            <h3 className={styles.subheading}>План закупок</h3>
                            <ul className={styles.list}>
                                <li><a href="/path/to/report1.pdf" target="_blank">План государственных закупок с учетом изменений и дополнений на 2024</a></li>
                                
                            </ul>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    );
};

export default Company;
