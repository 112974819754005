import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import { withTranslation } from "react-i18next"; // Подключаем i18n
import forestImg from "../../assets/3094035.jpg";
import appImg from "../../assets/app.jpg";
import smartImg from "../../assets/Умный_автобус.jpg";
import "./CarouselBox.css"; // Подключаем стили

class CarouselBox extends Component {
  render() {
    const { t } = this.props; // Получаем функцию перевода

    return (
      <div className="carouselbox-container">
        <Carousel>
          <Carousel.Item>
            <img className="d-block carouselbox-img" src={forestImg} alt="Forest Slide" />
            <Carousel.Caption className="carouselbox-caption-custom">
              <h3>{t("future")}</h3>
              <p>{t("future_desc")}</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block carouselbox-img" src={smartImg} alt="Smart Bus Slide" />
            <Carousel.Caption className="carouselbox-caption-custom">
              <h3>{t("safety")}</h3>
              <p>{t("safety_desc")}</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block carouselbox-img" src={appImg} alt="App Slide" />
            <Carousel.Caption className="carouselbox-caption-custom">
              <h3>{t("mobility")}</h3>
              <p>{t("mobility_desc")}</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default withTranslation()(CarouselBox); // Оборачиваем компонент для перевода
